<form [formGroup]="form">
  <div class="flex-container space-between">
    <h3>Source :</h3>
    <mat-form-field >
      <mat-label>Choisir un environnement source</mat-label>
      <!-- Environnement source -->
      <mat-select [(ngModel)]="selectedData.envSource" formControlName="sourceEnvironment">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let item of environmentList" [value]="item">{{ item }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Code Bu source -->
    <mat-form-field class="example-full-width">
      <mat-label>Code Bu source</mat-label>
      <input matInput placeholder="Code Bu source" [(ngModel)]="selectedData.codeBuSource" formControlName="codeBuSource">
    </mat-form-field>
  </div>

  <mat-divider></mat-divider>
  <div class="flex-container space-between">
    <h3>Destination :</h3>
    <mat-form-field>
      <mat-label>Choisir un environnement destination</mat-label>
      <!-- Environnement Destination -->
      <mat-select [(ngModel)]="selectedData.envDest" formControlName="destinationEnvironment">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let item of environmentList" [value]="item">{{ item }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- Code Bu destination -->
    <mat-form-field class="example-full-width">
      <mat-label>Code Bu destination</mat-label>
      <input matInput type="text" placeholder="Code Bu source" [(ngModel)]="selectedData.codeBuDest" formControlName="codeBuDestination">
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button mat-raised-button class="cancelButton" color="warn" (click)="cancelButton()">Annuler</button>
    <button mat-raised-button class="validButton" (click)="validButton()">Lancer la bascule</button>
  </mat-card-actions>
</form>
<div class="center">
  <h2 style="text-align: center;">RESULTAT</h2>
  <ng-container *ngIf="status == null || status == undefined || status ==0">
    <p><strong>Aucun résultat pour le moment.</strong></p>
  </ng-container>
  <ng-container *ngIf="status == 200">
    <mat-icon  aria-hidden="false" aria-label="Example home icon" fontIcon="done_all" [ngStyle]="{'color':'green'}"></mat-icon>
    <h2 [ngStyle]="{'color':'green'}">{{ message }}</h2>
  </ng-container>
  <ng-container *ngIf="status > 0 && status !=200">
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="error"  [ngStyle]="{'color':'red'}"></mat-icon>
    <h2 [ngStyle]="{'color':'red'}">{{ message }}</h2>
  </ng-container>

  <ng-container *ngIf="loaderService.isLoading |async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </ng-container>
</div>
<div class="center" *ngIf="readerCounter != undefined && readerCounter != null">
  <mat-vertical-stepper [linear]="false" #stepper [animationDuration]="'200'">
    <mat-step *ngFor="let enum of logNameEnum | keyvalue">
      <ng-template matStepLabel>{{ enum.value}}  {{ readerCounter != undefined ? readerCounter[""+enum.key] : '' }}</ng-template>
      <app-generic-table *ngIf="logbasculeHistory[''+enum.key] != undefined && logbasculeHistory[''+enum.key]?.length >0 " [data]="logbasculeHistory[''+enum.key]"></app-generic-table>
    </mat-step>
  </mat-vertical-stepper>

</div>
