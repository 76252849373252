import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { PaginatorStructure } from 'src/app/models/paginatorStructure';


@Component({
  selector: 'app-left',
  templateUrl: './left.component.html',
  styleUrls: ['./left.component.css']
})
export class LeftComponent implements OnInit, AfterViewInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
  }

  @Input() basculeData:MatTableDataSource<any> = new MatTableDataSource();;
  displayedColumns: string[] = ['code-bascule', 'date-debut', 'date-fin', 'status','etat'];
  selectedRow:any;
  // Strcuture de la pagination
  @Input() paginationStructure:PaginatorStructure = {
    arialabel :"Enregistrements par page :",
    pageNumber: 0,
    pageSize:0,
    pageSizeOptions:[5,10,25,100],
    totalElements:0
  } ;
  // Event enclenché lorsqu'on clique sur une ligne du tableau
  @Output() rowClickedEmit = new EventEmitter<any>();
  @Output() pageEventEmit = new EventEmitter();
  // init de la pagination
  @ViewChild(MatPaginator) paginator: MatPaginator = <MatPaginator>{};
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

  }

  checkboxSelectionClick(event:any)
  {

  }


  rowClicked(event:any, row:any, column:any){
    this.selectedRow= row;
    this.rowClickedEmit.emit([row, event]);

  }

   /**
   *
   * @param event
   */
   handlePageEvent(event:any){
    console.log(event);

    let page = event.pageIndex;
    let size = event.pageSize;
    let obj:any = { };
    obj.page = event.pageIndex;
    obj.size = event.pageSize;
    this.pageEventEmit.emit(obj)

  }

}
