export class GenericField {

  idLog:number = 0;
	status:string ="";
	message:string ="";

	idSource:number = 0;
	idDestination:number = 0;

	envSource:string ="";
	codeBuSource:string ="";

	envDest:string ="";
	codeBuDest:string ="";

	logDate:Date = new Date();
  constructor() {

  }
}
