<mat-card class="card">
  <mat-card-header class="card-header">
    <div style="display: inline-flex;">
      <mat-icon class="header-icon" >{{ icon }}</mat-icon><h4 *ngIf="isTitleVisible">{{ title }}</h4>
      <mat-card-title class="zoomIcon">
        <mat-icon class="header-icon" *ngIf="isResizeIconVisible" (click)="zoomSide()">{{ screenType }}</mat-icon>
      </mat-card-title>
    </div>
  </mat-card-header>
  <mat-card-content>
    <ng-content>

    </ng-content>
  </mat-card-content>

</mat-card>
