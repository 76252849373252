import { GenericField } from "./GenericFiled";

export class LogBascule extends GenericField {

  isBascule:boolean = false;

	logDateFin:Date = new Date();

	codeBascule:string = "";

  constructor() {
    super();
  }
}
