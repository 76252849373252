import { Directive, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { ActionEnum } from '../enums/actionEnum';

@Directive({
  selector: '[appAppDoubleClick]'
})
export class AppDoubleClickDirective implements OnDestroy {

  @Output() doubleClick = new EventEmitter();

  @Output() oneClick = new EventEmitter();

  timer:any = 0;
  stopClick:boolean = false;


  constructor() { }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  @HostListener('click', ['$event']) onClick(e:any){
    this.timer = 0;
    this.stopClick=false;
    const delay = 200;
    this.timer = setTimeout(() => {
      if (!this.stopClick) {
        this.oneClick.emit(ActionEnum.oneClick);
      }
    },delay);
  };


    @HostListener('dblclick', ['$event']) onDblClick(e:MouseEvent){
    e.preventDefault();
    e.stopPropagation();
    this.stopClick = true;
    clearTimeout(this.timer);

    this.doubleClick.emit(ActionEnum.doubleClick);
  };
}
