<ng-container *ngIf="status ==200">
  <mat-icon  aria-hidden="false" aria-label="Example home icon" fontIcon="done_all" [ngStyle]="{'color':'green'}"></mat-icon>
  <h2 [ngStyle]="{'color':'green'}">{{ message }}</h2>
</ng-container>
<ng-container *ngIf="status > 0 && status !=200">
  <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="error"  [ngStyle]="{'color':'red'}"></mat-icon>
  <h2 [ngStyle]="{'color':'red'}">{{ message }}</h2>
</ng-container>
<ng-container *ngIf="loaderService.isLoading |async">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-container>
<div class="center">
  <mat-vertical-stepper [linear]="false" #stepper [animationDuration]="'200'">
    <mat-step *ngIf="ejList != undefined || ejList != null">
      <ng-template matStepLabel>Entite juridique</ng-template>
      <app-generic-table [data]="ejList"></app-generic-table>
    </mat-step>
    <!--  -->
    <mat-step *ngIf="enseigneList != undefined || enseigneList != null">
      <ng-template matStepLabel>Enseigne</ng-template>
      <app-generic-table [data]="enseigneList"></app-generic-table>
    </mat-step>
    <!-- Site -->
    <mat-step *ngIf="siteList != undefined || siteList != null">
      <ng-template matStepLabel>Site</ng-template>
      <app-generic-table [data]="siteList"></app-generic-table>
    </mat-step>
  </mat-vertical-stepper>

</div>
