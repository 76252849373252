<table mat-table [dataSource]="basculeData" class="mat-elevation-z8 demo-table">
  <!-- Position Column -->
  <ng-container matColumnDef="code-bascule">
    <th mat-header-cell *matHeaderCellDef> No. </th>
    <td mat-cell *matCellDef="let element"> {{element.codeBascule}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="date-debut">
    <th mat-header-cell *matHeaderCellDef> Date début </th>
    <td mat-cell *matCellDef="let element"> {{element.logDate| date:'dd/MM/yyyy' }} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="date-fin">
    <th mat-header-cell *matHeaderCellDef> Date fin </th>
    <td mat-cell *matCellDef="let element"> {{element.logDateFin| date:'dd/MM/yyyy' }} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="etat">
    <th mat-header-cell *matHeaderCellDef> Etat </th>
    <td mat-cell *matCellDef="let element"> {{element.message}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    [ngClass]="row == selectedRow ?'selected' : ''"
    (click)="rowClicked($event, row,displayedColumns)"></tr>
</table>
<mat-paginator #paginator
               class="demo-paginator"
               (page)="handlePageEvent($event)"
               [length]="paginationStructure.totalElements"
               [pageSize]="paginationStructure.pageSize"
               [pageSizeOptions]="paginationStructure.pageSizeOptions"
               [showFirstLastButtons]="true"
               aria-label="Select page">
</mat-paginator>
