import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError, Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient) { }

  /**
   * Lancement de la bascule
   * @param envSource
   * @param envDest
   * @param codeBuSource
   * @param codeBuDest
   * @returns
   */
  startBascule(envSource:string, envDest:string, codeBuSource:string, codeBuDest:string){

    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule?envSource=${envSource}&envDest=${envDest}&codeBuSource=${codeBuSource}&codeBuDest=${codeBuDest}`,{
            responseType: 'json' }).pipe(
            catchError((error: HttpErrorResponse) =>{
              console.log("erreur")
              return throwError(error);
            })
          );
  }

  /**
   * Arreter la bascule en cours
   * @param envSource
   * @param envDest
   * @param codeBuSource
   * @param codeBuDest
   */
  stopCurrentBascule(envSource:string, envDest:string, codeBuSource:string, codeBuDest:string){

    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule/stopbascule?envSource=${envSource}&envDest=${envDest}&codeBuSource=${codeBuSource}&codeBuDest=${codeBuDest}`,{
      responseType: 'json' }).pipe(
      catchError((error: HttpErrorResponse) =>{
        console.log("erreur")
        return throwError(error);
      })
    );
  }

  /**
   * on vérifie l'état de la bascule si en cours ou non
   * @param envSource
   * @param envDest
   * @param codeBuSource
   * @param codeBuDest
   * @returns
   */
  checkCurrentBascule(envSource:string, envDest:string, codeBuSource:string, codeBuDest:string){

    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule/checkBasculeActived?envSource=${envSource}&envDest=${envDest}&codeBuSource=${codeBuSource}&codeBuDest=${codeBuDest}`,{
      responseType: 'json' }).pipe(
      catchError((error: HttpErrorResponse) =>{
        console.log("erreur")
        return throwError(error);
      })
    );
  }


  /**
   *
   * @param envSource
   * @param envDest
   * @param codeBuSource
   * @param codeBuDest
   * @returns
   */
  getbasculeInfo(envSource:string, envDest:string, codeBuSource:string, codeBuDest:string){
    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule/basculeinfo?envSource=${envSource}&envDest=${envDest}&codeBuSource=${codeBuSource}&codeBuDest=${codeBuDest}`,{
      responseType: 'json' }).pipe(
      catchError((error: HttpErrorResponse) =>{
        console.log("erreur")
        return throwError(error);
      })
    );
  }

  /**
   * Récupération de l'historique des logs
   */
  getAllBasculeHistory(page:number, size:number): Observable<any>{
    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule/basculehistory?page=${page}&size=${size}`,{
      responseType: 'json'}).pipe(
        catchError((error: HttpErrorResponse) =>{
          console.log("erreur");
          return throwError(error);
        })
    );
  }

  /**
   * on récupere le nombre d'élement disponible pour la bascule de chaque entité
   * @param envSource
   * @param envDest
   * @param codeBuSource
   * @param codeBuDest
   */
  getTotalEntityReader(envSource:string, envDest:string, codeBuSource:string, codeBuDest:string){

    return this.http.get(`${environment.apiUrl}${environment.apiContextPath}/bascule/basculereader?envSource=${envSource}&envDest=${envDest}&codeBuSource=${codeBuSource}&codeBuDest=${codeBuDest}`,{
      responseType: 'json' }).pipe(
      catchError((error: HttpErrorResponse) =>{
        console.log("erreur")
        return throwError(error);
      })
    );
  }

  getheader() {
    const contentHeaders = new Headers();
    contentHeaders.append('Accept', 'application/json');
    contentHeaders.append('Content-Type', 'application/json');
    let headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'text/plain',
      "Accept": "text/plain"
    });

    return { headers: headers };
  }
}
