import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BasculeComponent } from './layout/bascule/bascule.component';
import { CenterComponent } from './layout/component/center/center.component';
import { GenericTableComponent } from './layout/component/generic-table/generic-table.component';
import { LeftComponent } from './layout/component/left/left.component';
import { RightComponent } from './layout/component/right/right.component';
import { InterceptorService } from './services/handler/interceptor.service';
import { EnveloppeComponent } from './layout/component/enveloppe/enveloppe.component';
import { MiddleComponent } from './layout/component/middle/middle.component';
import { AppDoubleClickDirective } from './directives/app-double-click.directive';
import {MatPaginatorModule} from '@angular/material/paginator';

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY hh:mm',
  },
  display: {
    dateInput: 'DD/MM/YYYY hh:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY hh:mm',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  declarations: [
    AppComponent,
    BasculeComponent,
    GenericTableComponent,
    LeftComponent,
    RightComponent,
    CenterComponent,
    EnveloppeComponent,
    MiddleComponent,
    AppDoubleClickDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    HttpClientModule,
    MatCardModule,
    MatDividerModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatStepperModule,
    MatTableModule,
    ScrollingModule,
    MatPaginatorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi:true
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMAT
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
