<section class="table-container mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="data" class="mat-elevation-z8 demo-table">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> Date bascule </th>
      <td mat-cell *matCellDef="let element"> {{element.logDate| date:'dd/MM/yyyy'}} à {{ element.logDate| date:'HH:mm' }} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef> Message </th>
      <td mat-cell *matCellDef="let element"> {{element.message}} </td>
    </ng-container>

    <tr mat-header-row sticky *matHeaderRowDef="displayedColumns;sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</section>

