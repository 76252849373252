import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LogNameEnum } from 'src/app/enums/logNameEnum';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { Shared } from 'src/app/shared/shared';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.css']
})
export class RightComponent extends Shared implements OnInit {


  @Output() validButtonEmit = new EventEmitter<any>();
  @Output() cancelButtonEmit = new EventEmitter<any>();
  form:FormGroup = new FormGroup({
    codeBuSource: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
    codeBuDestination: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
    sourceEnvironment: new FormControl('',Validators.required),
    destinationEnvironment: new FormControl('',Validators.required)
  });


  @Input() selectedData:any;
  @Input() message:any;
  @Input() status:number=0;
  @Input() readerCounter:any;
  @Input() logbasculeHistory:any;
  environmentList: string[] = ["DEV","INT","PREPROD","PROD"];
  logNameEnum: any = LogNameEnum;

  ejFiltered:any;
  enseigneFiltered:any;
  siteFiltered:any;
  constructor( public loaderService: LoaderService){
    super(loaderService);
  }

  ngOnInit(): void {

  }

  /**
   * Lorsqu'on appuie sur le bouton valider
   */
  validButton(){
    this.validButtonEmit.emit();
  }

  /**
   * Lorsqu'on appuie sur le bouton annuler
   */
  cancelButton(){
    this.cancelButtonEmit.emit();
  }

}
