/**
 * On rassemble le nom des entités qui votn s'afficher dans le composant du right
 */
export enum LogNameEnum{
  ej = "Entite juridique",
  enseignes = "Enseigne",
  sites ="Site",
  parente = "Parente",
  tableBase = "Tables de base",
  typeLieu  = "Type lieu",
  lieu = "Lieu",
  concession = "Concession",
  typeTiers = "Type tiers",
  tiers  ="Tiers",
  profil = "Profil",
  employee = "Employee",
  c14Config = "c14Config",
  ordonnancements = "ordonnancements",
  banqueCaisse = "Banque-Caisse",
  compteComptable = "Compte comptable",
  modeReglement = "Mode de réglement",
  etapeRelance = "Etapes relance",
  entete = "Paramétrage des entêtes",
  pied = "Paramétrage des Pieds",
  texteHautDePage = "Paramétrage des textes haut de page",
  texteBasDePage = "Paramétrage des textes bas de page",
  document = "Paramétrage des documents",
  servicesService = "Service",
  familleService = "Famille service",
  sfArticle = "Sous-familles article",
  typeActivite = "Type activite",
  familleArticle = "Famille article",
  tva = "TVA",
  article = "Article",
  articleSousReference = "Article Sous références"
}
