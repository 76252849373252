import { Shared } from './../../shared/shared';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ConstanteService } from 'src/app/constanteServices/constante.service';
import { ApiService } from 'src/app/services/api.service';
import { LoaderService } from './../../services/loader/loader.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-bascule',
  templateUrl: './bascule.component.html',
  styleUrls: ['./bascule.component.css'],
})
export class BasculeComponent extends Shared implements OnInit, OnDestroy {

  basculeHistoryList: any;
  timerId:any ;
  interval:any;
  cpt:number = 0;

  constructor(
    private apiService: ApiService,
    public loaderService: LoaderService,
    public constante: ConstanteService
  ) {
    super(constante);
  }

  ngOnDestroy(): void {

    if (this.constante.selectedItem.isBascule) {
      clearInterval(this.timerId);
    }
  }

  ngOnInit(): void {
    this.getAllBasculeHistory(0,10);
  }

  /**
   * lorsqu'on lance la bascule
   * on fait appel à starBascule
   * puis toutes les 5 secondes on lance deux autres requetes tant que la bascule n'est pas terminée
   */
  getData() {
    this.apiService
      .startBascule(
        this.constante.selectedItem.envSource,
        this.constante.selectedItem.envDest,
        this.constante.selectedItem.codeBuSource,
        this.constante.selectedItem.codeBuDest
      )
      .subscribe(
        (response: any) => {
          this.constante.message = response.message;
          this.constante.status = response.status;
          console.log(response);

        },
        (error) => {
          this.constante.message = error.error.message;
          this.constante.status = error.error.status;
        }
      );
        this.interval= setInterval(() => {

          this.checkbasculeRunning();
          this.getTotalEntityReader();
            this.getBasculeInfo();


          if (this.cpt > 0 &&  !this.constante.selectedItem.isBascule && this.loaderService.isLoading.value ==true) {
            this.getAllBasculeHistory(0,10);
            clearInterval(this.interval);
          }
          this.cpt++;
        }, 5000);
  }

  /**
   * Recuperer toute l'historique des bascules depuis LogBascule
   * ces données récuperées seront afficher a gauche (left component)
   */
  getAllBasculeHistory(page:number, size:number) {
    this.basculeHistoryList = this.apiService
      .getAllBasculeHistory(page,size)
      .subscribe((data: any) => {
        this.constante.historicBasculeData = new MatTableDataSource(data.content) ;
        this.constante.pagination.pageNumber = data.pageable.pageNumber;
        this.constante.pagination.pageSize = data.pageable.pageSize;
        this.constante.pagination.totalElements = data.totalElements;
      });
  }

  /**
   * Récupere les logs d'une bascule avec les messages d'erreurs s'il y'a n'en
   * exemple : log entité, log enseigne etc
   */
  getBasculeInfo() {
  //  this.loaderService.isLoading.next(true);
    this.apiService
      .getbasculeInfo(
        this.constante.selectedItem.envSource,
        this.constante.selectedItem.envDest,
        this.constante.selectedItem.codeBuSource,
        this.constante.selectedItem.codeBuDest
      )
      .subscribe(
        (response: any) => {
          this.constante.logBasculeInfo = response;
        //  this.loaderService.isLoading.next(true);
        },
        (error) => {
        //  this.loaderService.isLoading.next(true);
        }
      );
      console.log("loaderccc");
  }

  /**
   * Lorsqu'on appuie sur le bouton annuler
   */
  cancelBascule() {

    this.constante.canceledBascule();
    if (this.constante.selectedItem.envSource != undefined && this.constante.selectedItem.envSource != "") {
      this.apiService
      .stopCurrentBascule(
        this.constante.selectedItem.envSource,
        this.constante.selectedItem.envDest,
        this.constante.selectedItem.codeBuSource,
        this.constante.selectedItem.codeBuDest
      )
      .subscribe(
        (response: any) => {
          console.log(response);

          this.constante.message = response.body;
          this.constante.status = response.statusCodeValue;
        },
        (error) => {
          console.log(error);
          this.constante.message = error.error.message;
          this.constante.status = error.error.status;
        }
      );
    }

  }

  /**
   * on vérifie si la bascule est toujours en cours ou non
   * si bascule arretée ou terminée on met a jours isBacule à false
   */
  checkbasculeRunning(){
    this.apiService
      .checkCurrentBascule(
        this.constante.selectedItem.envSource,
        this.constante.selectedItem.envDest,
        this.constante.selectedItem.codeBuSource,
        this.constante.selectedItem.codeBuDest
      )
      .subscribe(
        (response: any) => {
         // if (response.body.isBascule == false) {
            this.constante.message = response.body.message;
            this.constante.status = response.body.status;
            this.constante.selectedItem.isBascule = response.body.isBascule;

        //  }
          //console.log(response.body.message);
        },
        (error) => {
          this.constante.message = error.error.message;
          this.constante.status = error.error.status;
        }
      );
  }

  /**
   * quand on selectionne une ligne du tableau on affecte cette data à la constante selectedItem, puis on affiche le composant de droite.
   * On fait appel à getbasculeInfo
   * @param event
   */
  getItemSelectedFromLeft(event: any) {
    this.constante.selectedItem = event[0];
    this.constante.message = event[0].message;
    this.constante.status = event[0].status;

    this.getTotalEntityReader();
    this.getBasculeInfo();
    this.constante.rowClicked(event);
  }

  /**
   * on récupere le nombre d'élement disponible pour la bascule de chaque entité
   */
  getTotalEntityReader() {
    this.loaderService.isLoading.next(true);
    this.apiService
      .getTotalEntityReader(
        this.constante.selectedItem.envSource,
        this.constante.selectedItem.envDest,
        this.constante.selectedItem.codeBuSource,
        this.constante.selectedItem.codeBuDest
      )
      .subscribe(
        (response: any) => {
          console.log(response);
          this.constante.entityCount = response;
          this.loaderService.isLoading.next(false);
        },
        (error) => {
          this.constante.entityCount = null;
          this.loaderService.isLoading.next(false);
        }
      );
  }

    /**
   * lorsqu'on change de page a travers le paginateur
   * @param event
   */
    pageChangeEvent(event: any) {
      this.getAllBasculeHistory(event.page, event.size);
    }
}
