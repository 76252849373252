<div id="container">
  <div class="center">
    <mat-card class="card-button">
      <mat-card-header class="headerTitle" style="display: inline-flex;">
        <button mat-raised-button class="createButton" style="margin-bottom: 15px;color: white;background-color: var(--header-font-color);" (click)="constante.createBascule()">Créer une bascule</button>
        <h1 class="title" style="position: absolute;right: 35%">Carbone 14 Evolution bascule</h1>
      </mat-card-header>
    </mat-card>
  </div>
  <!--  -->
  <div class="left" *ngIf="constante.isLeftVisible" [ngStyle]="{'order':constante.leftOrder, 'width': constante.leftWidth}">
    <app-enveloppe
      [side]="'left'"
      [title]="constante.leftTitle"
      [icon]="constante.leftIcon"
      [isLeftIconVisible]="constante.leftisLeftIconVisible"
      [isTitleVisible]="constante.leftIsTitleVisible"
      [screenType] = "constante.leftImageSize"
      [isResizeIconVisible]="constante.leftIsResizeIconVisible"
      [hasButtonAction]="constante.leftHasButtonAction"
      (sideEmit)="constante.manageSide($event)"
      >
      <app-left
        [basculeData]="constante.historicBasculeData"
        (rowClickedEmit)="getItemSelectedFromLeft($event)"
        (pageEventEmit) ="pageChangeEvent($event)"
        [paginationStructure]="constante.pagination"></app-left>
    </app-enveloppe>
  </div>

  <!--  -->
  <div class="right" *ngIf="constante.isRightVisible" [ngStyle]="{'order':constante.rightOrder, 'width': constante.rightWidth}">
    <app-enveloppe
      [side]="'right'"
      [title]="constante.rightTitle"
      [icon]="constante.leftIcon"
      [isLeftIconVisible]="constante.leftisRghtIconVisible"
      [isTitleVisible]="constante.leftIsTitleVisible"
      [screenType] = "constante.rightImageSize"
      [isResizeIconVisible]="constante.rightIsResizeIconVisible"
      [hasButtonAction]="constante.rightHasButtonAction"
      (sideEmit)=" constante.manageSide($event)">
      <app-right
      [selectedData]="constante.selectedItem"
      (validButtonEmit)="getData()"
      (cancelButtonEmit)="cancelBascule()"
      [logbasculeHistory] = "constante.logBasculeInfo"
      [message]="constante.message"
      [status]="constante.status"
      [readerCounter]="constante.entityCount"
      ></app-right>
    </app-enveloppe>
  </div>
</div>
