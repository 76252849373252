import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.css']
})
export class MiddleComponent {

  constructor( public loaderService: LoaderService){}
  message:any;
  status:number=0;
  ejList:any;
  enseigneList:any;
  siteList:any;

}
