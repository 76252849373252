import { Component, Input, OnInit } from '@angular/core';
import { TableColumn } from 'src/app/models/tableColumn';


@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css']
})
export class GenericTableComponent implements OnInit {

  ngOnInit(): void {
    // this.columns.forEach(element => {
    //   console.log(element.name);
    // //  this.displayedColumns.push(element.name);

    // });
    // console.log(this.displayedColumns);

  }

  @Input() data:any;
  //@Input() columns:TableColumn[] = []; // les colonnes qui seront dans la table
  displayedColumns: string[] = ['id', 'status', 'date', 'message'];
}
