import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LogBascule } from '../models/Logbascule';
import { PaginatorStructure } from '../models/paginatorStructure';

@Injectable({
  providedIn: 'root'
})
export class ConstanteService {

  public leftZoom: string = '';
  public rigthZoom: string = '';
  public middleZoom: string = '';
  public isLeftVisible: boolean = true; // visibilité du composant de gauche
  public isRightVisible: boolean = false; // visibilité du composant de droite
  public isMiddleVisible: boolean = true; // visibilité du composant de du centre
  public leftImageSize: string = "fullscreen_exit"; // taille de l'image de gauche dans l'enveloppe
  public rightImageSize: string = "fullscreen"; // taille de l'image de droite dans l'enveloppe
  public middleImageSize: string = "fullscreen"; // taille de l'image du centre dans l'enveloppe
  public leftTitle:string = "historique des bascules"; // titre a gauche
  public rightTitle:string = "Détail bascule"; // titre à droite
  public middleTitle:string = "Resultat"; // titre au milieu
  public leftIcon:string = "list";  // liste icone à gauche
  public rightIcon:string = "edit"; //liste icones à droite
  public middleIcon:string = "edit"; //liste icones à droite
  public isLeftFooterVisible:boolean = false; // visibilité du pied de page côté gauche
  public isRightFooterVisible:boolean = true; // visibilité du pied de page côté droite
  public rightHasButtonAction:boolean= true; // Si contient un bon au footer
  public form:FormGroup  = new FormGroup({
    codeBuSource: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
    codeBuDestination: new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
    sourceEnvironment: new FormControl('',Validators.required),
    destinationEnvironment: new FormControl('',Validators.required)
  }); // formulaire
  public selectedItem:LogBascule = new LogBascule(); // data de la ligne choisie depuis le tableau à gauche
  public logBasculeInfo:any = null;
  public logEntiteJuridique:any;
  public logEnseigne:any;
  public logSite:any;
  public logParente:any;
  public logTableBase:any;
  public logConcession:any;
  public logTypeTiers:any;
  public logTiers:any;
  public logTypeLieu:any;
  public logLieu:any;
  public message:string="";
  public status:number=0;
  public entityCount:any;

  public historicBasculeData:any; // liste de toutes les données liées à l'historique de la bascule

  /**Initialisation Pour la partie de gauche */
  public leftisLeftIconVisible:boolean = false;

  public leftIsTitleVisible:boolean = true;

  public leftIsFilterIconVisible:boolean = false;

  public leftIsResizeIconVisible:boolean = true;

  public leftHasButtonAction:boolean= false; // Si contient un bon au footer

  public leftOrder: string = "1";

  public leftWidth:string ="100%";

  /**Pour la partie de droite */
  public leftisRghtIconVisible:boolean = false;

  public rightIsTitleVisible:boolean = true;

  public rightIsFilterIconVisible:boolean = false;

  public rightIsResizeIconVisible:boolean = true;

  public isMiddleFooterVisible:boolean = false; // visibilité du pied de page côté droite

  public rightOrder:string = "2";

  public rightWidth:string = "100%"

  /**
   * Pour la partie du milieu
   */
  public leftisMiddleIconVisible:boolean = false;

  public middleIsTitleVisible:boolean = true;

  public middleIsResizeIconVisible:boolean = false;

  public middleHasButtonAction:boolean= false; // Si contient un bon au footer

  public middleOrder: string = "3";

  public middleWidth:string ="100%";

  public rowIndex:number =0;

  /**
   * gestion du resize
   * @param event
   */
  manageSide(event:string)
  {
    if (event == "left") {
      if (this.leftWidth == "100%") {
        this.leftWidth = "49%";
        this.rightWidth = "50%";
        this.leftImageSize = "fullscreen";
        this.isRightVisible = true;
      } else {
        this.leftWidth = "100%";
        this.leftImageSize = "fullscreen_exit";
        this.isRightVisible = false;
      }
    }
    if (event == "right") {
      if (this.rightWidth == "100%") {
        this.rightWidth = "50%";
        this.leftWidth = "49%";
        this.rightImageSize = "fullscreen";
        this.isLeftVisible = true;
      } else {
        this.rightWidth = "100%";
        this.rightImageSize = "fullscreen_exit";
        this.isLeftVisible = false;
      }

    }
  }
/**
 * Clique sur une ligne de la table
 * @param event
 */
  rowClicked(event:any){
    this.rightWidth = "50%";
    this.leftWidth = "49%";
    this.rightImageSize = "fullscreen";
    this.leftImageSize = "fullscreen";
    this.isLeftVisible = true;
    this.isRightVisible=true;

  }

  /**
   * action when click on button create bascule
   */
  createBascule(){
    this.selectedItem = new LogBascule();
    this.message ="";
    this.status = 0;
    this.logEntiteJuridique =null;
    this.logEnseigne = null;
    this.logSite=null;
    this.rightWidth = "100%";
    this.rightImageSize = "fullscreen";
    this.leftImageSize = "fullscreen";
    this.isLeftVisible = false;
    this.isRightVisible=true;
    this.rightImageSize = "fullscreen_exit";
    this.entityCount = null;
  }

  /**
   * action when cancel bascule
   */
  canceledBascule(){
        this.leftWidth = "49%";
        this.rightWidth = "50%";
        this.leftImageSize = "fullscreen";
        this.rightImageSize = "fullscreen";
        this.isLeftVisible = true;
        this.isRightVisible = true
  }

  /**
   * Pagination
   */

  public pagination:PaginatorStructure = {
    arialabel:"Enregistrements par page :",
    pageNumber:0,
    totalElements:0,
    pageSize:10,
    pageSizeOptions:[5, 10, 25, 100]
  };


  constructor() { }
}
