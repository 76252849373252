export class Shared {
  private const:any = {};
  constructor(constante:any) {
    this.const = constante;
  }

  manageSide(event:string)
  {
    alert("event")
  }
}
