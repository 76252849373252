import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-enveloppe',
  templateUrl: './enveloppe.component.html',
  styleUrls: ['./enveloppe.component.css']
})
export class EnveloppeComponent implements OnInit {

  @Input() title:string = "" ;

  @Input() side:string = "";

  @Input() screenType:string = "";

  @Input() icon:string = "";

  @Input() isLeftIconVisible:boolean = false;

  @Input() isTitleVisible:boolean = true;

  @Input() isAddIconVisible:boolean= false;

  @Input() isFilterIconVisible:boolean = false;

  @Input() isResizeIconVisible:boolean = true;

  @Input() hasButtonAction:boolean  = false;

  @Output() sideEmit = new EventEmitter<any>();

  ngOnInit(): void {

  }

/**
 * determine le côté de l'enveloppe a agrandir
 */
zoomSide(){
  this.sideEmit.emit(this.side);
}

}
